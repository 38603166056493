@import "npm:modern-normalize/modern-normalize.css";

/* Required Core Stylesheet */
@import "../../node_modules/@glidejs/glide/src/assets/sass/glide.core.scss";
/* Optional Theme Stylesheet */
@import "../../node_modules/@glidejs/glide/src/assets/sass/glide.theme.scss";

@font-face {
    font-family: 'suisse';
    src: url('suisseintl-regular.woff2') format('woff2'),
         url('suisseintl-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'suisse';
    src: url('suisseintl-light.woff2') format('woff2'),
         url('suisseintl-light.woff') format('woff');
    font-weight: 300;
    font-style: light;
}

@font-face {
    font-family: 'suisse-mono-thin';
    src: url('suisseintlmono-thin.woff2') format('woff2'),
         url('suisseintlmono-thin.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

:root {
  --background-color: #f1f1f1;
  --foreground-color: #232323;
}

html,
body {
  height: 100%;
}
body {
  font-size: 16px;
  line-height: 24px;
  font-family: "suisse", sans-serif;
  font-weight: 300;
  overflow: auto;
  scroll-snap-type: y proximity;
  background-color: var(--background-color);
}
html {
  overflow: hidden;
}
a {
  color: var(--foreground-color);
}

header {
  scroll-snap-align: always;
  color: var(--foreground-color);
}
header .text {
  max-width: 660px;
  padding: 3em 0;
  margin: 0 5vw;
}

.portfolio + .portfolio {
  margin-top: 1em;
}
section {
  scroll-snap-align: start;
}
.portfolio {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.portfolio-image {
  display:flex;
  flex-direction: column;
  margin-inline: 5vw;
  margin-block: 0 2em;
}
.portfolio img {
  width: 90vw;
  max-height: 80vh;
  object-fit: contain;
}
.portfolio .project-title {
  font-weight: 300;
  text-decoration: none;
}
.portfolio .project-tags {
  font-family: 'suisse-mono-thin', monospace;
  font-size: 0.5em;
}

.portfolio figcaption {
  align-self: end;
  color: var(--foreground-color); 
  background-color: var(--background-color);
  margin: 0 0 -0.6em 0;
  padding: 0.3em 0 0 0.2em;
  text-align: right;
  font-size: 2em;
  line-height: 1em;
  z-index: 1;
}

footer {
  scroll-snap-align: start;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 6em 5vw 2em;
}
.footer-copy {
  font-family: 'suisse-mono-thin', monospace;
}
.footer-image {
  mix-blend-mode: multiply;
  width: 15vw;
  min-width: 180px;
  max-width: 300px;
}

@media screen and (min-width: 720px) {
  body {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
}
@media screen and (min-width: 1448px) {
  body {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}
